exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-compatibility-assessment-tsx": () => import("./../../../src/pages/compatibility-assessment.tsx" /* webpackChunkName: "component---src-pages-compatibility-assessment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nesting-survey-tsx": () => import("./../../../src/pages/nesting-survey.tsx" /* webpackChunkName: "component---src-pages-nesting-survey-tsx" */),
  "component---src-pages-reference-table-tsx": () => import("./../../../src/pages/reference-table.tsx" /* webpackChunkName: "component---src-pages-reference-table-tsx" */),
  "component---src-pages-search-standards-tsx": () => import("./../../../src/pages/search-standards.tsx" /* webpackChunkName: "component---src-pages-search-standards-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

